import { getWordPressProps, WordPressTemplate } from "@faustwp/core";
import type { GetStaticPropsContext } from "next";

import type { PageProps } from "@/types";
import { GLOBAL_REVALIDATE } from "@/constants/revalidate";
import DriftIFrame from "@/components/atoms/DriftIFrame";

export default function Page(props: PageProps) {
  return (
    <>
      <WordPressTemplate {...props} />
      <DriftIFrame />
    </>
  );
}

export function getStaticProps(ctx: GetStaticPropsContext) {
  return getWordPressProps({ ctx, revalidate: GLOBAL_REVALIDATE });
}
