export default function loadDriftIframe() {
  const DRIFT_SRC =
    "https://d38muu3h4xeqr1.cloudfront.net/website/static/current/drift-frame.html";

  const iframe = document.createElement("iframe");
  iframe.src = DRIFT_SRC;
  iframe.id = "drift-iframe";
  iframe.title = "drift-iframe";
  iframe.setAttribute("style", "border: 0; height: 0px; position: absolute;");
  document.body.appendChild(iframe);
}
