import { useEffect } from "react";
import loadDriftIframe from "@/utilities/loadDriftIframe";

export default function DriftIFrame() {
  let didInit = false;
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      // ✅ Only runs once per app load
      // Check for previously loaded drift iframe and remove it
      const drift = document.getElementById("drift-iframe");
      drift && drift.remove();

      // Instantiate a new drift iframe per page (each page has it's own bot)
      loadDriftIframe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
